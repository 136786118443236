import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformMinutesToHours'
})
export class TransformMinutesToHoursPipe implements PipeTransform {

  transform(value: any): any {
    if (value === null || value === 'null') {
      return `0 horas`
    }


    if (value >= 60) {
      const hours = Math.floor(value / 60)
      const minutes = value % 60
      if (minutes === 0) {
        if (hours === 1) {
          return `${hours} hora`
        }
        return `${hours} horas`
      }
      return `${hours} h ${minutes} m`
    } else {
      return `${value} m`
    }

  }

}
