import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import {Const} from '../util/const'
import {LoginService} from './login.service'
import {FormBuilder, FormControl, Validators} from '@angular/forms'
import {ProductsModel} from '../models/products.model'

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  productModel = ProductsModel
  constructor(private http: HttpClient,
              private loginService: LoginService,
              private formB: FormBuilder,
  ) { }

  createFormProducts() {
    return this.formB.group({
      [this.productModel.ID_CIUDAD]: new FormControl('', Validators.compose([Validators.required])),
      [this.productModel.NOMBRE]: new FormControl('', Validators.compose([Validators.required,
        Validators.minLength(10), Validators.maxLength(30)])),
      [this.productModel.DESCRIPCION]: new FormControl('', Validators.compose([Validators.required,
        Validators.minLength(20), Validators.maxLength(255)])),
      [this.productModel.IMAGEN]: new FormControl('', Validators.compose([Validators.required])),
      [this.productModel.ORDEN]: new FormControl('', Validators.compose([Validators.required])),
      [this.productModel.ESTADO]: new FormControl('', Validators.compose([Validators.required])),
    })
  }

  getProducts(options) {
    options = {
      ...options,
      ...this.loginService.getSesion()
    }
    return this.http.post(Const.serverURL + 'admin/todosProducto', options, Const.headers)
  }

  enableDisableProduct(product) {
    product = {
      ...this.loginService.getSesion(),
      ...product,
    }
    return this.http.post(Const.serverURL + 'admin/ActivarDesactivarProducto', product, Const.headers)
  }

  getProductsByFranquicia(franquicia) {
    return this.http.post(Const.serverURL + 'admin/ProductosPorFranquicia', franquicia, Const.headers)
  }

}
