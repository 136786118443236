import { Injectable } from '@angular/core'
import { LoginService } from './login.service';
import { HttpClient } from '@angular/common/http'
import { Const } from '../util/const'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { ClientsService } from './clients.service';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  validationMessages = {
    plate: [
      { type: 'required', message: 'La placa es requerida' },
      { type: 'minlength', message: 'La placa debe ser minimo de 5 caracteres.' },
      { type: 'maxlength', message: 'La placa debe ser como maximo de 7 caracteres.' },
    ],
    typeVehicle: [
      { type: 'required', message: 'Seleccione un tipo de vehiculo' }
    ],
    marca: [
      { type: 'required', message: 'Seleccione una marca' }
    ]
  }

  constructor(
    private http: HttpClient,
    private formB: FormBuilder,
    private clientsService: ClientsService,
    private loginService: LoginService) { }

  getVehicles(user?) {
    let data
    if (user ) {
      data = user
    } else {
      data = this.clientsService.getUser()
    }
    return this.http.post(Const.serverURL + 'shared/vehiculosUsuario', data, Const.headers)
  }

  listVehicles() {
    return this.http.post(Const.serverURL + 'shared/TodosVehiculos', null, Const.headers)
  }

  deleteVehicle(vehicle) {
    const user = this.clientsService.getUser()
    const admin = this.loginService.getUser()
    vehicle.idUsuario = user.idUsuario
    vehicle.token = admin.token
    vehicle.email = admin.email
    return this.http.post(Const.serverURL + 'shared/EliminarVehiculo', vehicle, Const.headers)
  }

  updateVehicle(vehicle) {
    const user = this.clientsService.getUser()
    const admin = this.loginService.getUser()
    vehicle.idUsuario = user.idUsuario
    vehicle.token = admin.token
    vehicle.email = admin.email
    return this.http.post(Const.serverURL + 'shared/ActualizarVehiculo', vehicle, Const.headers)
  }

  createVehicle(vehicle) {
    const user = this.clientsService.getUser()
    const admin = this.loginService.getUser()
    vehicle.idUsuario = user.idUsuario
    vehicle.token = admin.token
    vehicle.email = admin.email
    return this.http.post(Const.serverURL + 'shared/CrearVehiculo', vehicle, Const.headers)
  }

  createFormVehicle(vehicleModel) {
    return this.formB.group({
      [vehicleModel.PLATE]: new FormControl('', Validators.compose([
        Validators.required, Validators.minLength(5), Validators.maxLength(7)
      ])),
      [vehicleModel.TYPE_VEHICLE]: new FormControl('', Validators.compose([Validators.required])),
      [vehicleModel.BRAND]: new FormControl('', Validators.compose([Validators.required]))
    })
  }
}
