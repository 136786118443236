import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Const } from '../util/const'
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class CorporativosService {

  constructor(
    private http: HttpClient, private loginService: LoginService) { }

  createCorporativo(data) {
    data = {
      ...data,
      email: this.loginService.user.email,
      token: this.loginService.user.token
    }
    return this.http.post(Const.serverURL + 'admin/CrearCorporativo', data, Const.headers)
  }

  seachCorporativo(data){
    return this.http.post(Const.serverURL + 'admin/BuscarCorporativo', data, Const.headers)
  }

  getAllCorporativos(){
    return this.http.post(Const.serverURL + 'admin/todosCorporativos', 
    {
        email: this.loginService.user.email,
        token: this.loginService.user.token
    }
    , Const.headers)
  }
  
  updateCorporativo(data) {
    data = {
      ...data,
      email: this.loginService.user.email,
      token: this.loginService.user.token
    }
    return this.http.post(Const.serverURL + 'admin/editarCorporativo', data, Const.headers)
  }

  getAllCorporativoWasher(data){
    data = {
      ...data,
      email: this.loginService.user.email,
      token: this.loginService.user.token
    }
    return this.http.post(Const.serverURL + 'admin/listaUsuarioCorporativo', data, Const.headers)
  }

  getAllCorporativosServicio(datos){
    let data = {
      idcorporativo: datos.id,
      email: this.loginService.user.email,
      token: this.loginService.user.token
    }
    return this.http.post(Const.serverURL + 'admin/TodosServiciosCorporativo', data, Const.headers)
  }


 
}