import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'servicesFilter',
    pure: false
})
export class ServicesFilterPipe implements PipeTransform {
    transform(services: any[], filters): any[] {
        if ( !services || !filters ) {
            return services
        }
        let name: string
        const statesServices = Array(filters.state).join()
        if ( filters.type == 1) {
            name = 'conductor'
        } else {
            name = 'idservicio'
        }
        return services.filter( serivice => (String(serivice[name]).toLowerCase().indexOf(String(filters.text).toLowerCase()) !== -1 &&
            String(statesServices).indexOf(String(serivice.idestadoservicio)) !== -1 ))
    }
}
