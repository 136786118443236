import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransformMinutesToHoursPipe } from '../../pipes/transform-minutes-to-hours.pipe';
import {ServicesFilterPipe} from '../../pipes/services-filter.pipe'

@NgModule({
  declarations: [
    TransformMinutesToHoursPipe,
    ServicesFilterPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TransformMinutesToHoursPipe,
    ServicesFilterPipe
  ]
})
export class PipesModule { }
